import * as React from "react";
import {Field} from "formik";
import MaskedInput from "react-maskedinput";

const CustomInputPhone = ({name, ...props}) =>  (
    <Field name={name}>
        {
            ({ field, form }) => (
                <MaskedInput
                    className="form-control"
                    {...props}
                    {...field}
                    onBlur={(e) => {
                        form.setFieldValue(field.name, e.target.value.replace(/ /g, ''))
                    }}
                />
            )
        }
    </Field>
)

export default CustomInputPhone;