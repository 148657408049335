import React from "react";
import {Formik} from "formik";
import {InputPhoneComponent} from "../inputs";
import {Link} from "react-router";

const EmailVerificationForm = ({ submit }) => {
    return (
        <div className={"w-50"}>
            <p className={"display-4 lead"}>Verification du compte</p>
            <p className={'lead'}>Verification du compte par la validation du l'adresse email.</p>
            <Formik
                initialValues={{otp: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Cette information est requis';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'L\'adresse email est invalide';
                    }
                    return errors;
                }}
                onSubmit={(values) => {
                    submit(values);
                }}
            >
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <InputPhoneComponent name="otp"
                                                       mask={"1 1 1 1 1 1"}
                                                       className="form-control p-3"
                                                       id="input_email_sign-in"
                                                       placeholder="OTP"
                                                       required/>
                            <label htmlFor="input_email_sign-in">Email</label>
                        </div>
                        <button className="btn btn-primary p-2 mb-3 w-100" type="submit">
                            Verification de email
                        </button>
                        <Link to={'/user/sign_up'}
                              className={"btn btn-link text-decoration-none text-secondary p-2 w-100"}>
                            Vous avez pas de compte ? Inscrivez-vous.
                        </Link>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default EmailVerificationForm;