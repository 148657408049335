import * as React from "react";
import {Field} from "formik";

const CustomInputEmail = ({name, ...props}) =>  (
    <Field name={name}>
        {
            ({ field, form, meta }) => (
                <input type="email" className="form-control" {...props} {...field} />
            )
        }
    </Field>
)

export default CustomInputEmail;