import {useDropzone} from 'react-dropzone';
import Card from 'react-bootstrap/Card';
import {useCallback, useState} from "react";
import {MdOutlineUploadFile} from "react-icons/md";
import {IoCheckmarkSharp, IoClose, IoDocument} from "react-icons/io5";

const CustomDropzoneComponent = ({uploadMethod}) => {
    const [file, setFile] = useState(null);
    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    if (file) {
        return (
            <div className={"card p-3 mb-3"}>
                <div className={"d-flex pb-2"}>
                    <IoDocument size={66}/>
                    <p className={"lead"}>Nom : {file.name} <br/>Type: {file.type}</p>
                </div>
                <div>
                    <div className={"d-flex align-items-center"}>
                        <button className={"btn btn-success me-2"}
                                onClick={() => uploadMethod(file)}>Valider <IoCheckmarkSharp/></button>
                        <button className={"btn btn-secondary"}
                                onClick={() => setFile(null)}>Rétirer <IoClose/></button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ? (
                    <div className="card">
                        <div className={"d-flex justify-content-start align-items-center  p-3"}>
                            <MdOutlineUploadFile size={70}/>
                            <p className={"lead"}>Déposez <br/>le fichier ici...</p>
                        </div>
                    </div>
                ) : (
                    <div className="card">
                        <div className={"d-flex justify-content-start align-items-center p-3"}>
                            <MdOutlineUploadFile size={70}/>
                            <p className={"lead"}>Faites glisser déposez vos images ici, <br/> ou cliquez pour
                                sélectionner vos images</p>
                        </div>
                    </div>
                )

            }
        </div>
    );
}

export default CustomDropzoneComponent;