import {useSetRecoilState} from "recoil";
import ModalStore from "../../../../store/Modal";

const Order = () => {
    const setModalStatus = useSetRecoilState(ModalStore);

    return (
        <div className={"w-100 h-100"}>
            <p className={"display-4 lead"}>Commande de traduction</p>
            <p className={'lead'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium ad aliquam
                asperiores consequuntur dolore ea error facere hic id</p>
            <br/>
            <button className={"btn btn-outline-primary"} onClick={() => setModalStatus({key: 'order-translate-modal', status: true})}>
                Faire une traduction
            </button>
        </div>
    )
}

export default Order;