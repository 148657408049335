import {Modal} from "react-bootstrap";
import {useRecoilState, useSetRecoilState} from "recoil";
import ModalStore from "../../store/Modal";
import {useEffect, useState} from "react";
import useIsMounted from "../../hooks/useIsMounted";
import {DropzoneComponent} from "../inputs";
import {ProgressBarStore} from "../../store/Loading";

const OrderTranslateModal = () => {
    const [show, setShow] = useState(false);
    const setIsRunning = useSetRecoilState(ProgressBarStore);
    const isMounted = useIsMounted();
    const [modalStatus, setModalStatus] = useRecoilState(ModalStore);
    const [order, setOrder] = useState(null);

    const handlerUploadFile = (value) => {
        setIsRunning(true)
        console.log(value);
    }

    const handleClose = () => setModalStatus({
        key: 'order-translate-modal',
        state: false,
    });

    useEffect(() => {
        if (isMounted() && modalStatus?.key === 'order-translate-modal') {
            setShow(modalStatus?.status);
        }
    }, [isMounted, modalStatus])

    return (
        <Modal
            show={show}
            size="lg"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body>
                <div className={"d-flex align-items-end flex-column mb-3 w-100"}>
                    <div className="px-5 w-100">
                        <DropzoneComponent uploadMethod={handlerUploadFile}/>
                    </div>
                    <div className="p-5 w-100">

                    </div>
                    <div className="mt-auto px-5 hstack">
                        <button className={"btn btn-secondary me-auto"} onClick={handleClose}>
                            Annuler
                        </button>
                        <button className={"btn btn-outline-primary"} onClick={handleClose}>
                            Passer votre commande de traduction
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OrderTranslateModal;