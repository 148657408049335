import React from 'react';
import ReactDOM from 'react-dom/client';
import {CookiesProvider} from "react-cookie";
import App from './App';
import { HashRouter } from "react-router";

import './assets/fonts/Quicksand-Regular.ttf';
import './assets/fonts/Quicksand-Medium.ttf';
import './assets/fonts/Quicksand-Light.ttf';
import './assets/fonts/Quicksand-SemiBold.ttf';
import './assets/fonts/Quicksand-Bold.ttf';

import 'bootstrap/dist/css/bootstrap.min.css';
import {RecoilRoot} from "recoil";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HashRouter basename="/">
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <RecoilRoot>
                <App />
            </RecoilRoot>
        </CookiesProvider>
    </HashRouter>
);

