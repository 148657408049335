import * as React from 'react';
import {useField} from "formik";

const CustomTextarea = (props) => {
    const [field] = useField(props);

  return (
      <div className="form-floating">
          <textarea {...field} className={props.className} />
          {props.label}
      </div>
  )
}

export default CustomTextarea;