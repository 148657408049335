
const userSession = () => {
    let userSession = localStorage.getItem("@user_session");
    if (!userSession) {
        return null;
    }

    return JSON.parse(userSession);
}

const getLocalRefreshToken = () => {
    let userSession = localStorage.getItem("@user_session")

    if (!userSession) {
        return null;
    }

    let user = JSON.parse(userSession);
    return user?.refreshToken;
}

const getLocalAccessToken = () => {

    let userSession = localStorage.getItem("@user_session")

    if (!userSession) {
        return null;
    }

    const user = JSON.parse(userSession);
    return user?.token;
}

const updateLocalAccessToken =  (token, refreshToken) => {
    let userSession = localStorage.getItem("@user_session")

    if (!userSession) {
        return null;
    }

    let user = JSON.parse(userSession);
    user.token = token;
    user.refreshToken = refreshToken;
    localStorage.setItem("@user_session", JSON.stringify(user));
}

const setUserSession = (value) => {
    localStorage.setItem("@user_session", JSON.stringify(value));
}

const removeUserSession = () => {
    localStorage.removeItem("@user_session");
}

export {
    getLocalAccessToken,
    getLocalRefreshToken,
    updateLocalAccessToken,
    userSession,
    setUserSession,
    removeUserSession
};