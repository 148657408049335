import { atom } from 'recoil';

const ModalStore = atom({
    key: 'modal_state',
    default: {
        key: '',
        status: false
    },
});

export default ModalStore;
