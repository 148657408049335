import * as React from 'react';
import {Field} from "formik";

const CustomInputText = ({name, ...props}) => {

    return (
        <Field name={name}>
            {
                ({ field, form, meta }) => (
                    <input type="text" className="form-control" {...props} {...field} />
                )
            }
        </Field>
    )
}

export default CustomInputText;