import {Formik} from "formik";
import {InputEmailComponent, InputPasswordComponent} from "../inputs";
import {Link} from "react-router";

const SignInForm = ({ submit }) => {
    return (
        <div className={"w-50"}>
            <p className={"display-4 lead"}>Connexion</p>
            <p className={'lead'}>Se connecter à l'application</p>
            <Formik
                initialValues={{username: '', password: '', _remember_me: false}}
                validate={values => {
                    const errors = {};
                    if (!values.username) {
                        errors.username = 'Cette information est requis';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
                        errors.username = 'L\'adresse email est invalide';
                    }

                    if (values.password.length < 6) {
                        errors.password = 'Password must be at least 6 characters long';
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    submit(values);
                    resetForm(true);
                }}
            >
                {({errors, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <InputEmailComponent name="username"
                                                 className="form-control p-3"
                                                 id="input_email_sign-in"
                                                 placeholder="Email"
                                                 required
                                                 autoComplete="email"/>
                            <label htmlFor="input_email_sign-in">Email</label>
                        </div>
                        {errors.password && <b className="text-danger">{errors.password}</b>}
                        <div className="form-floating mb-3">
                            <InputPasswordComponent name="password"
                                                    className="form-control p-3"
                                                    id="input_password_sign-in"
                                                    placeholder="Mot de passe"
                                                    required
                                                    autoComplete="new-password"/>
                            <label htmlFor="input_password_sign-in">Mot de passe</label>
                        </div>
                        <Link className={"fw-light text-secondary text-decoration-none"} to={"/user/reset_password"}>Avez-vous oublier votre mot de passe? Cliquer ici</Link>
                        <div className="form-check pt-3 pb-3">
                            <input className="form-check-input"
                                   type="checkbox"
                                   name="_remember_me"
                                   id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckDefault">Se souvenir de moi</label>
                        </div>
                        <button className={"btn btn-primary p-2 mb-3 w-100"} type="submit">
                            Se connecter
                        </button>
                        <Link to={'/user/sign_up'}
                              className={"btn btn-link text-decoration-none text-secondary p-2 w-100"}>
                            Vous avez pas de compte ? Inscrivez-vous.
                        </Link>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default SignInForm;