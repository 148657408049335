import { atom, selector } from 'recoil';
import {setUserSession, userSession} from "../../services/UserSessionStorageService";

const AuthenticationStore = atom({
  key: 'authentication_store',
  default: {
    storageUser: null,
    isConnected: false,
    account: null,
  },
});

export const AuthenticationSelector = selector({
  key: 'authentication_selector',
  get: async () => {
    try {
      return userSession()
    } catch (error) {
      return null;
    }
  },
  set: ({ get, set }, payload) => {
    let { isConnected } = get(AuthenticationStore);

    if (isConnected) {
      return;
    }

    setUserSession(payload)
    set(AuthenticationStore, {
      storageUser: payload,
      isConnected: true,
    });
  },
});

export default AuthenticationStore;
