import {Formik} from "formik";
import {InputEmailComponent, InputPasswordComponent} from "../inputs";
import {Link} from "react-router";
import React from "react";

const InitResetPasswordForm = ({ submit }) => {
    return (
        <div className={"w-50"}>
            <p className={"display-4 lead"}>Réinitialisation de mot de passe</p>
            <p className={'lead'}>Nécessite votre adresse email utilisé pour la création du compte.</p>
            <Formik
                initialValues={{email: '', password: '', confirm_password: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Cette information est requis';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'L\'adresse email est invalide';
                    }

                    return errors;
                }}
                onSubmit={(values) => {
                    console.log(values);
                }}
            >
                {({errors, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        {errors.email && <b className="text-danger">{errors.email}</b>}
                        <div className="form-floating mb-3">
                            <InputEmailComponent name="email" id="init_password_form_email" className="form-control p-3"
                                                 placeholder="Email" autoComplete="email" required/>
                            <label htmlFor="init_password_form_email">
                                Email
                            </label>
                        </div>
                        <br/>
                        <button className="btn btn-primary p-2 mb-3 w-100" type="submit">
                            Réinitialisation du mot de passe
                        </button>
                        <Link to={'/user/sign_in'}
                              className={"btn btn-link text-decoration-none text-secondary p-2 w-100"}>
                            Vous avez un compte ? Connectez-vous.
                        </Link>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default InitResetPasswordForm;