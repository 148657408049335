import { atom } from 'recoil';

export const ProgressBarStore = atom({
  key: 'progress_bar_state',
  default: false,
});

const LoadingStore = atom({
  key: 'loading_state',
  default: false,
});

export default LoadingStore;
