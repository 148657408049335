import {FooterPageComponent, HeaderPageComponent} from "./components";
import {Link} from "react-router";
import {IoCloudDownloadSharp, IoCloudUpload, IoLanguage, IoLogoLinkedin, IoMail, IoWallet} from "react-icons/io5";
import {useRecoilValue, useSetRecoilState} from "recoil";
import Slider from "react-slick";
import ModalStore from "../../../store/Modal";
import AuthenticationStore from "../../../store/Authentication";

import BG_IMAGE from '../../../assets/images/body-bg.jpg';
import IMAGES from '../../../assets/images/portrait-african.jpg';
import IMAGE_1 from '../../../assets/images/image_1.jpg';
import IMAGE_2 from '../../../assets/images/image_2.jpg';
import PARTNER_1 from '../../../assets/images/partner_1.jpg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ContactComponentForm} from "../../../components/forms";
import {IoLogoFacebook} from "react-icons/io";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 4,
    slidesToShow: 4
};

const Home = () => {
    const setModalStatus = useSetRecoilState(ModalStore);
    const authentication = useRecoilValue(AuthenticationStore);

    return (
        <div
            className={"w-100 m-0 p-0"}
            style={{
                backgroundImage: `linear-gradient(rgba(245 245 245 / 90%), rgba(253 253 253 / 40%)), url(${BG_IMAGE})`,
            }}
        >
            <HeaderPageComponent authentication={authentication}/>
            <section className={"container py-5 w-100"}>
                <div className={"row"}>
                    <div className={"col-md-6 py-5 pe-5"}>
                        <p className={"badge lead fw-bold text-bg-secondary"}>Garantie un raduction fidél à 100%</p>
                        <h1 className="display-1 mb-5 ">
                            <IoLanguage/>
                            <br/><b>Transly</b>
                        </h1>
                        <p className="lead mb-5">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci asperiores beatae
                            consequatur
                            eligendi eos eveniet ex exercitationem explicabo fugiat
                        </p>
                        {
                            authentication?.isConnected ? (
                                <a type={"button"} className={'btn btn-lg btn-outline-primary'}
                                   onClick={() => setModalStatus({key: 'order-translate-modal', status: true})}>
                                    Demander un dévis
                                </a>
                            ) : (
                                <Link to={"/user/sign_in"} className={'btn btn-lg btn-dark ms-3'}>
                                    Demander un dévis
                                </Link>
                            )
                        }
                    </div>
                    <div className={"col-md-6 py-5"}>
                        <img src={IMAGES} alt="" className={"img-fluid"} width={"100%"}/>
                    </div>
                </div>
                <div className={"d-flex justify-content-center align-items-center py-5 w-100"}>
                    <div className={"card shadow-sm border-0 text-bg-dark hstack p-3 me-2 w-50"}>
                        <p className={"text-center"}>
                            <IoCloudUpload size={62} />
                        </p>
                        <p className={"w-auto fs-5 fw-bold px-3"}>
                            Téléverser votre document à traduit.
                        </p>
                    </div>
                    <div className={"card shadow-sm border-0 text-bg-primary hstack p-3 w-50"}>
                        <p className={"text-center"}>
                            <IoWallet size={62} />
                        </p>
                        <p className={"w-auto fs-5 fw-bold px-3"}>
                            Payer les frais de traduction en ligne.
                        </p>
                    </div>
                    <div className={"card shadow-sm border-0 text-light bg-info hstack p-3 ms-2 w-50"}>
                        <p className={"text-center"}>
                            <IoCloudDownloadSharp size={62} />
                        </p>
                        <p className={"w-auto fs-5 fw-bold px-3"}>
                            Et récupérer votre document traduit.
                        </p>
                    </div>
                </div>
            </section>
            <section className={"container-fluid d-flex justify-content-center bg-dark py-2"}>
                <img src={PARTNER_1} alt="partner 1" className={"img-fluid m-1"} style={{width: "10%"}} />
                <img src={PARTNER_1} alt="partner 1" className={"img-fluid m-1"} style={{width: "10%"}} />
                <img src={PARTNER_1} alt="partner 1" className={"img-fluid m-1"} style={{width: "10%"}} />
                <img src={PARTNER_1} alt="partner 1" className={"img-fluid m-1"} style={{width: "10%"}} />
            </section>
            <section className={'w-100 bg-light py-5'}>
                <div className={"container py-5"}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <h1 className={"display-1"}>A propos de nous</h1>
                            <p className={"lead"}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid aspernatur
                                blanditiis, cum dolor earum eos esse eum excepturi illo incidunt ipsam laboriosam
                                necessitatibus
                                nihil perspiciatis quisquam recusandae, totam ut.
                            </p>
                            <p className={"lead"}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid aspernatur
                                blanditiis,
                            </p>
                        </div>
                        <div className={"col-md-6 d-flex justify-content-evenly align-items-center text-center"}>
                            <div className={"w-100 m-1"}>
                                <div className="card bg-light w-100 m-1">
                                    <div className="card-body">
                                        <p className={"fs-1 fw-bold"}>+ 1k</p>
                                        <p className={"lead"}>Client heureux</p>
                                    </div>
                                </div>
                                <div className="card bg-light w-100 m-1">
                                    <div className="card-body">
                                        <p className={"fs-1 fw-bold"}>+ 20k</p>
                                        <p className={"lead"}>Fichier traduit</p>
                                    </div>
                                </div>
                            </div>
                            <div className={"w-100 m-1"}>
                                <div className="card bg-light w-100 m-1">
                                    <div className="card-body">
                                        <p className={"fs-1 fw-bold"}>+ 2k</p>
                                        <p className={"lead"}>Connexion</p>
                                    </div>
                                </div>
                                <div className="card bg-light w-100 m-1">
                                    <div className="card-body">
                                        <p className={"fs-1 fw-bold"}>+ 100</p>
                                        <p className={"lead"}>Inscrire par mois</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to={"/free-quote"} className={"btn btn-lg btn-dark my-5"}>Tous savoir sur nous</Link>
                </div>
            </section>
            <section className={"w-100 bg-white py-5"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-md-6 py-5"}>
                            <div className="row">
                                <div className={"col-md-12 col-sm-12 p-0"}>
                                    <div className="row">
                                        <div className={"col-md-6 col-sm-6 mb-3"}>
                                            <img src={IMAGE_1} alt={""} className={"rounded-5 w-100"}/>
                                        </div>
                                        <div className={"col-md-6 col-sm-6 mb-3"}>
                                            <img src={IMAGE_2} alt={""} className={"rounded-5 w-100"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 p-0 m-0">
                                    <div className="row">
                                        <div className={"col-md-6 col-sm-6"}>
                                            <img src={IMAGE_2} alt={""} className={"rounded-5 w-100"}/>
                                        </div>
                                        <div className={"col-md-6 col-sm-6"}>
                                            <img src={IMAGE_1} alt={""} className={"rounded-5 w-100"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-6 p-5"}>
                            <h1 className={"display-1"}>Comment ça marche</h1>
                            <br/>
                            <p className={"lead"}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad adipisci, atque
                                debitis earum eligendi enim et explicabo ipsa maxime neque nihil nobis praesentium quasi
                                ratione!
                            </p>
                            <p className={"lead"}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad adipisci, atque
                                debitis earum eligendi enim et
                            </p>
                            <div className={"my-5"}>
                                <div className={"hstack"}>
                                    <div className="">
                                        <IoCloudUpload size={22}/>
                                    </div>
                                    <div className="p-2">Téléverser votre document à traduit.</div>
                                </div>
                                <div className={"hstack"}>
                                    <div className="">
                                        <IoWallet size={22}/>
                                    </div>
                                    <div className="p-2">Payer les frais de traduction en ligne.</div>
                                </div>
                                <div className={"hstack"}>
                                    <div className="">
                                        <IoCloudDownloadSharp size={22}/>
                                    </div>
                                    <div className="p-2">Et récupérer votre document traduit.</div>
                                </div>
                            </div>
                            <Link to={"/free-quote"} className={"btn btn-dark btn-lg"}>Demander un dévis</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"text-bg-dark py-5"}>
                <div className={"container"}>
                    <div className="row">
                        <div className={"col-md-6"}>
                            <h1 className={"display-1"}>Contactez-nous</h1>
                            <p className="lead">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error ex illum nulla temporibus voluptatum? Asperiores autem, deserunt dolore esse explicabo facilis ipsa laboriosam non reiciendis rerum sit soluta velit voluptatum?
                            </p>
                            <div className={"py-5"}>
                                <div className="bd-example">
                                    <address>
                                        <strong>Transly </strong><br/>
                                        Cocody ,<br/>
                                        Abidjan, Côte d'Ivoire<br/>
                                        <abbr title="Phone">P:</abbr> (225) 22 20 00 00 00
                                    </address>
                                    <address>
                                        <strong></strong>

                                    </address>
                                </div>
                                <div className={"d-flex justify-content-start align-items-center"}>
                                    <a href="https://www.facebook.com" className={"text-decoration-none"}><IoLogoFacebook size={32}/></a>
                                    <a href="https://www.linkedin.com" className={"text-decoration-none"}><IoLogoLinkedin size={32}/></a>
                                    <a href="mailto:support@transly.com" className={"text-decoration-none"}><IoMail size={32}/></a>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-6 mb-3"}>
                            <ContactComponentForm/>
                        </div>
                    </div>
                </div>
            </section>
            <FooterPageComponent/>
        </div>
    )
}

export default Home;