import {UpdatePasswordForm} from "../forms";

const Security = () => {
    const handleUpdatePasswordSubmit = (values) => {
        console.log(values);
    }

    return (
        <div className={"w-100 h-100"}>
            <p className={"display-4 lead"}>Parametre de securiter</p>
            <p className={'lead'}>Modification des mot de passe.</p>
            <br/>
            <UpdatePasswordForm submit={handleUpdatePasswordSubmit} />
        </div>
    )
}

export default Security;