import {Formik} from "formik";
import {InputEmailComponent, InputTextComponent, TextareaComponent} from "../inputs";

const ContactForm = () => {
    return (
        <Formik
            initialValues={{email: '', fullName: '', message: ''}}
            validate={values => {
                const errors = {};
                if (!values.email) {
                    errors.email = 'Cette information est requis';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = 'L\'adresse email est invalide';
                }
                return errors;
            }}
            onSubmit={(values, { resetForm }) => {
                console.log(values)
                resetForm({email: '', fullName: '', message: ''})
            }}
        >
            {({handleSubmit, errors}) => (
                <form onSubmit={handleSubmit}>
                    <div className={"p-2 row g-3"}>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_full_name" className={"form-label"}>Nom et prénom</label>
                            <InputTextComponent name="fullName"
                                                id="input_full_name"
                                                className="form-control p-3"/>
                            {errors.fullName && <b className="text-danger">{errors.fullName}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_email_name" className={"form-label"}>E-mail</label>
                            <InputEmailComponent name="email"
                                                 id="input_email_name"
                                                 className="form-control p-3"/>
                            {errors.email && <b className="text-danger">{errors.email}</b>}
                        </div>
                        <div className={"col-12"}>
                            <label htmlFor="input_message" className={"form-label"}>Message</label>
                            <TextareaComponent name="message"
                                               rows={4}
                                               id="input_message"
                                               className="form-control p-3"/>
                            {errors.message && <b className="text-danger">{errors.message}</b>}
                        </div>
                    </div>
                    <div className="p-5"></div>
                    <div className={"mt-auto p-2 w-100"}>
                        <button className={"btn btn-primary btn-lg p-2 mb-3"} type="submit">
                            Envoyer le message
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default ContactForm;