import * as React from "react";
import {Field} from "formik";
import CreatableSelect from "react-select/creatable";
import Select from "react-select/base";

const CustomInputSelect = ({name, items, ...props}) => {

    return (
        <Field name={name} as="select" className="form-control" {...props}>
            {
                [{key: null, value: 'Sélectionner'}, ...items].map((value, index) => (
                    <option value={value.value} key={index}>{value.label}</option>
                ))
            }
        </Field>
    )
}

const CustomCreatableSelect = ({name, items, ...props}) => {
    return (
        <Field name={name}>
            {
                ({field, form}) => (
                    <CreatableSelect
                        isClearable
                        options={items}
                        onChange={({label, value}) => {
                            field.setFieldValue(form.name, value)
                        }}
                    />
                )
            }
        </Field>
    )
}

const CustomInputSelectCategory = ({name, items, ...props}) => (
    <Field name={name} as="select" className="form-control" {...props}>
        {
            [{id: undefined, title: 'Selectionner'}, ...items].map((value, index) => (
                <option value={value.id} key={index}>{value.title}</option>
            ))
        }
    </Field>
)

const CustomInputMultiSelect = ({name, items, ...props}) => (
    <Field name={name}>
        {
            ({field, form}) => (
                <Select
                    isMulti
                    name={name}
                    defaultValue={[items[0], items[1]]}
                    options={items}
                    className="form-select"
                    classNamePrefix="select"
                    onChange={(newValue, actionMeta) => {
                        console.log(newValue);
                    }}
                />
            )
        }
    </Field>
)

export {
    CustomInputSelect,
    CustomCreatableSelect,
    CustomInputSelectCategory,
    CustomInputMultiSelect
};