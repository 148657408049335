import {Store} from "react-notifications-component";

const successFlashNotification = () => {
    Store.addNotification(
        {
            title: 'Opération effectuer',
            message: 'Votre demande a été bien prise en compte',
            type: 'success',
            container: 'top-right'
        }
    );
}

const customFlashNotification = (title, message, status) => {
    Store.addNotification(
        {
            title: title,
            message: message,
            type: status,
            container: 'top-right'
        }
    );
}

const errorFlashNotification = (error) => {
    switch (true) {
        case 'ERR_NETWORK' === error.code:
            Store.addNotification(
                {
                    title: 'Erreur réseau',
                    message: 'Verifier si appareil est connecter à internet',
                    type: 'danger',
                    container: 'top-right'
                }
            );
            break;
        case 'ERR_BAD_REQUEST' === error.code:
            Store.addNotification(
                {
                    title: 'Erreur sur la requête',
                    message: 'Nous ne pouvons donner suite à cette requête. Il semble que les données d\'entrer ne sont pas correcte',
                    type: 'danger',
                    container: 'top-right',
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                }
            );
            break;
        case 'ERR_BAD_RESPONSE' === error.code:
            Store.addNotification(
                {
                    title: 'Erreur inattendu',
                    message: 'Re-essayer si cette erreur persist contacter le service technique.\n\n',
                    type: 'danger',
                    container: 'top-right'
                }
            );
            break;
        default:
            console.log(error);
            break;
    }
}

export {
    successFlashNotification,
    errorFlashNotification,
    customFlashNotification
}