import axios from "axios";

const payload = {
    baseURL: "http://127.0.0.1:5000/api/v1",
    headers: {
        "Content-Type": "application/json",
    },
}

const instance = axios.create(payload);

instance.interceptors.request.use(
    (config) => {
        // const token = getLocalAccessToken();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        // const originalConfig = err.config;
        return Promise.reject(err);
    }
);

export default instance;
