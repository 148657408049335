import {Field} from "formik";
import * as React from "react";

const CustomInputPassword = ({name, ...props}) => (
    <Field name={name}>
        {
            ({ field }) => (
                <input type="password" className="form-control" {...field} {...props}/>
            )
        }
    </Field>
)

export default CustomInputPassword;