import {Formik} from "formik";
import {
    InputEmailComponent, InputPasswordComponent,
    InputPhoneComponent,
    InputTextComponent
} from "../../../components/inputs";

const ProfileForm = ({ submit }) => {
    return (
        <Formik
            initialValues={{lastname: '', firstname: '', phonenumber: '', email: '', address: '', city: '', country: ''}}
            onSubmit={values => submit(values)}
        >
            {({errors, handleSubmit }) => (
                <form className={"d-flex align-items-start flex-column w-100 mb-3"} style={{height: 200}} onSubmit={handleSubmit}>
                    <div className={"p-2 row g-3"}>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_first_name" className={"form-label"}>Prénom (s)</label>
                            <InputTextComponent name="firstname"
                                                id="input_first_name"
                                                className="form-control p-3"/>
                            {errors.firstname && <b className="text-danger">{errors.firstname}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_last_name" className={"form-label"}>Nom</label>
                            <InputTextComponent name="lastname"
                                                id="input_last_name"
                                                className="form-control p-3"/>
                            {errors.lastname && <b className="text-danger">{errors.lastname}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_email_name" className={"form-label"}>E-mail</label>
                            <InputEmailComponent name="email"
                                                 id="input_email_name"
                                                 className="form-control p-3"/>
                            {errors.email && <b className="text-danger">{errors.email}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_phonenumber_name" className={"form-label"}>Numéro de téléphone</label>
                            <InputPhoneComponent name="phonenumber"
                                                 mask={"11 11 11 11 11"}
                                                 id="input_phonenumber_name"
                                                 className="form-control p-3"/>
                            {errors.phonenumber && <b className="text-danger">{errors.phonenumber}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_city_name" className={"form-label"}>Ville</label>
                            <InputTextComponent name="city"
                                                id="input_city_name"
                                                className="form-control p-3"/>
                            {errors.city && <b className="text-danger">{errors.city}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_country_name" className={"form-label"}>Pays</label>
                            <InputTextComponent name="country"
                                                id="input_country_name"
                                                className="form-control p-3"/>
                            {errors.country && <b className="text-danger">{errors.country}</b>}
                        </div>
                    </div>
                    <div className="p-5"></div>
                    <div className={"mt-auto p-2 w-100"}>
                        <button className={"btn btn-primary btn-lg p-2 mb-3"} type="submit">
                            Sauvegarder
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    )
}

const UpdatePasswordForm = ({ submit }) => {
    return (
        <Formik
            initialValues={{old_password: '', new_password: '', confirm_new_password: ''}}
            validate={values => {
                const errors = {};

                if (values.old_password.length < 6) {
                    errors.old_password = 'Password must be at least 6 characters long';
                }

                if (values.new_password.length < 6) {
                    errors.new_password = 'Password must be at least 6 characters long';
                }

                if (values.confirm_new_password.length < 6) {
                    errors.confirm_new_password = 'Password must be at least 6 characters long';
                }

                if (values.confirm_new_password !== values.new_password) {
                    errors.confirm_password = 'Mot de passe de confirmation non correcte'
                }

                return errors;
            }}
            onSubmit={values => console.log(values)}
        >
            {({errors, handleSubmit }) => (
                <form className={"d-flex align-items-start flex-column w-100 mb-3"} style={{height: 200}}
                      onSubmit={handleSubmit}>
                    <div className={"p-2 row g-3"}>
                        <div className={"col-md-12"}>
                            <label htmlFor="input_old_password_name" className={"form-label"}>Ancient mot de
                                passe</label>
                            <InputPasswordComponent name="old_password"
                                                    id="input_old_password_name"
                                                    className="form-control p-3"/>
                            {errors.old_password && <b className="text-danger">{errors.old_password}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_new_password_name" className={"form-label"}>Nouveau mot de
                                passe</label>
                            <InputPasswordComponent name="new_password"
                                                    id="input_new_password_name"
                                                    className="form-control p-3"/>
                            {errors.new_password && <b className="text-danger">{errors.new_password}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_confirm_new_password_name" className={"form-label"}>Confirmer le mot
                                de psse</label>
                            <InputPasswordComponent name="confirm_new_password"
                                                    id="input_confirm_new_password_name"
                                                    className="form-control p-3"/>
                            {errors.confirm_new_password &&
                                <b className="text-danger">{errors.confirm_new_password}</b>}
                        </div>
                    </div>
                    <div className="p-5"></div>
                    <div className={"mt-auto p-2 w-100"}>
                        <button className={"btn btn-primary btn-lg p-2 mb-3"} type="submit">
                            Sauvegarder
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export {
    ProfileForm,
    UpdatePasswordForm
}