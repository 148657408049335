import {Link} from "react-router";

const NotFound = () => {
    return (
        <main className={"container py-5"}>
            <div className={"row"}>
                <div className={"col py-5"}>
                    <h1 className={"display-1"}>404</h1>
                    <p className={"fs-1"}>Page not fount</p>
                    <Link to={'/'} className={"btn btn-outline-primary"}>Page d'accueil</Link>
                </div>
            </div>
        </main>
    )
}

export default NotFound;