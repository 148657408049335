import {LayoutPage} from "./Components";
import {SignInComponentForm} from "../../../components/forms";
import ApiService from "../../../services/Requests/ApiService";
import {errorFlashNotification} from "../../../components/others/FlashNotification";
import {useSetRecoilState} from "recoil";
import {AuthenticationSelector} from "../../../store/Authentication";
import {useNavigate} from "react-router";
import {ProgressBarStore} from "../../../store/Loading";

const SignIn = () => {
    const setAuthentication = useSetRecoilState(AuthenticationSelector);
    const setIsRunning = useSetRecoilState(ProgressBarStore);
    const navigate = useNavigate();

    const submitFormHandler = (values) => {
        setIsRunning(true);
        ApiService
            .post("/auth/token",  JSON.stringify(values))
            .then((response) => {
                setAuthentication(response.data);
                setIsRunning(false);
                navigate('/');
            })
            .catch((error) => {
                errorFlashNotification(error)
                setIsRunning(false);
            })
    }

    return (
        <LayoutPage>
            <div className={"d-flex justify-content-center align-items-center"}>
                <SignInComponentForm submit={submitFormHandler} />
            </div>
        </LayoutPage>
    )
}

export default SignIn;