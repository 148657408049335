import useIsConnect from "../../../hooks/useIsConnect";
import React, {useEffect} from "react";
import {Link, Outlet, useNavigate} from "react-router";
import {IoLogoAlipay, IoLogOut, IoPerson, IoShieldSharp} from "react-icons/io5";
import {removeUserSession} from "../../../services/UserSessionStorageService";

const ProtectedLayoutComponent = ({children}) => {
    const isConnected = useIsConnect();
    const navigate = useNavigate();

    const handlerLogout = () => {
        removeUserSession()
        document.location.reload();
    }

    useEffect(() => {
        if (!isConnected) {
            navigate('/user/sign_in')
        }
    })

    return (
        <main className={"d-flex align-items-center align-self-stretch bg-light w-100 p-0 m-0"}>
            <div className={"d-flex vh-100 w-100 justify-content-start align-items-stretch"}>
                <div className={"bg-light h-auto w-25 d-none d-lg-block"} style={{height: 200}}>
                    <div className={"d-flex align-items-start flex-column h-100 p-5"}>
                        <div className={"mb-auto p-2"}>
                            <h1 className={"display-3 fw-bold"}>
                                <Link to={'/'} className={"text-decoration-none"}>Transly</Link>
                            </h1>
                        </div>
                        <div className={"p-2"}>
                            <div className={"d-flex mb-3"}>
                                <IoLogoAlipay size={22} className={"text-primary me-2"}/>
                                <Link to={'/user/translation_order'} className={"text-decoration-none fw-bold"}>
                                    Commande de traduction
                                </Link>
                            </div>
                            <div className={"d-flex mb-3"}>
                                <IoPerson size={22} className={"text-primary me-2"}/>
                                <Link to={'/user/profile'} className={"text-decoration-none fw-bold"}>
                                    Information personnel
                                </Link>
                            </div>
                            <div className={"d-flex mb-3"}>
                                <IoShieldSharp size={22} className={"text-primary me-2"}/>
                                <Link to={'/user/security'} className={"text-decoration-none fw-bold"}>
                                    Parametre de securiter
                                </Link>
                            </div>
                            <div className={"d-flex mb-3"}>
                                <IoLogOut size={22} className={"text-danger me-2"}/>
                                <a type={'button'} onClick={() => handlerLogout()} className={"text-decoration-none text-danger fw-bold"}>Se deconnecter</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'w-75 p-5 vh-100 bg-white'}>
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                        <div className={'w-75 h-100'}>
                        <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export {
    ProtectedLayoutComponent
}