import {ProfileForm} from "../forms";

const Profile = () => {
    const handlerUpdateTreatment = (values) => {
        console.log(values);
    }

    return (
        <div className={"w-100 h-100"}>
            <p className={"display-4 lead"}>Information personnel</p>
            <p className={'lead'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium ad aliquam asperiores consequuntur dolore ea error facere hic id</p>
            <br/>
            <ProfileForm submit={handlerUpdateTreatment} />
        </div>
    )
}

export default Profile;