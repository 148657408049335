import {
    DropzoneComponent,
    InputEmailComponent,
    InputPhoneComponent,
    InputSelectComponent,
    InputTextComponent, TextareaComponent
} from "../inputs";
import {Formik} from "formik";
import {useSetRecoilState} from "recoil";
import {ProgressBarStore} from "../../store/Loading";
import {useState} from "react";

const LANG = [
    {key: 'french', value: 'french', label: 'Français'},
    {key: 'english', value: 'english', label: 'Anglais'},
    {key: 'german', value: 'german', label: 'Allemand'},
    {key: 'spanish', value: 'spanish', label: 'Espagnol'},
]

const TRANSLATE_TYPE = [
    {key: 1, value: 'certification', label: 'Traduction certifiée'},
    {key: 2, value: 'standard', label: 'Traduction standard'},
]

const DELIVERY = [
    {key: 1, value: '12', label: 'Service prioritaire rapide (12 h)'},
    {key: 2, value: '48', label: 'Service standard (24 h)'},
    {key: 3, value: '72', label: 'Service régulier (24 h)'},
]

const DOCUMENT_TYPE = [
    {key: 1, value: 'certification', label: 'Attestation or certification'},
    {key: 2, value: 'other', label: 'Autre document'},
]

const FormComponent = ({ onSubmit }) => (
    <Formik initialValues={{}} onSubmit={(values) => {
        onSubmit(values);
    }}>
        {
            ({handleSubmit, errors}) => (
                <form className={"w-100 pt-5 mb-3"} style={{height: 200}}
                      onSubmit={handleSubmit}>
                    <div className={"py-2 row g-3"}>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_original_language" className={"form-label fw-bold"}>Langue du
                                document</label>
                            <InputSelectComponent name="original_language"
                                                  items={LANG}
                                                  id="input_original_language"
                                                  className="form-control p-3"/>
                            {errors.original_language && <b className="text-danger">{errors.original_language}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_final_language" className={"form-label fw-bold"}>Langue de
                                traduction</label>
                            <InputSelectComponent name="final_language"
                                                  items={LANG}
                                                  id="input_final_language"
                                                  className="form-control p-3"/>
                            {errors.final_language && <b className="text-danger">{errors.final_language}</b>}
                        </div>
                        <div className={"col-md-4"}>
                            <label htmlFor="input_translate_ype" className={"form-label fw-bold"}>Type de
                                traduction</label>
                            <InputSelectComponent name="translate_ype"
                                                  items={TRANSLATE_TYPE}
                                                  id="input_translate_ype"
                                                  className="form-control p-3"/>
                            {errors.translate_ype && <b className="text-danger">{errors.translate_ype}</b>}
                        </div>
                        <div className={"col-md-8"}>
                            <label htmlFor="input_document_ype" className={"form-label fw-bold"}>Type du document à
                                traduire</label>
                            <InputSelectComponent name="document_ype"
                                                  items={DOCUMENT_TYPE}
                                                  id="input_document_ype"
                                                  className="form-control p-3"/>
                            {errors.document_ype && <b className="text-danger">{errors.document_ype}</b>}
                        </div>
                        <div className={"col-12"}>
                            <label htmlFor="input_additional_notes" className={"form-label fw-bold"}>Vos notes
                                supplémentaires</label>
                            <TextareaComponent name="additional_notes"
                                               rows={4}
                                               id="input_additional_notes"
                                               className="form-control p-3"/>
                            {errors.additional_notes && <b className="text-danger">{errors.additional_notes}</b>}
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="input_delivery" className={"form-label fw-bold"}>Délai d'exécution *</label>
                            <InputSelectComponent name="delivery"
                                                  items={DELIVERY}
                                                  id="input_delivery"
                                                  className="form-control p-3"/>
                            {errors.delivery && <b className="text-danger">{errors.delivery}</b>}
                        </div>
                        <div className={"col-md-6 py-3"}>
                            <p className={"alert alert-light"}>
                                lorem ipsum dolor sit amet lorem ipsum dolor sit amet <br/>
                                lorem ipsum dolor sit amet
                            </p>
                        </div>
                    </div>
                    <div className="">
                    </div>
                    <div className={"mt-auto d-flex py-3 justify-content-between w-100"}>
                        <button className={"btn btn-dark btn-lg"} type="submit">
                            Annuler l'oppération
                        </button>
                        <button className={"btn btn-primary btn-lg"} type="submit">
                            Validé l'oppération
                        </button>
                    </div>
                </form>
            )
        }
    </Formik>
)

const FreeQuoteForm = ({submit}) => {
    const setIsRunning = useSetRecoilState(ProgressBarStore);
    const [file, setFile] = useState(null);
    const [order, setOrder] = useState(null);

    const handlerUploadFile = (value) => {
        setIsRunning(true)
        setFile(value);
    }
    const handlerForm = (value) => {
        console.log(value)
    }

    return (
        <div className={"w-100"}>
            {
                !file ? <DropzoneComponent uploadMethod={handlerUploadFile}/> : <FormComponent onSubmit={handlerForm}/>
            }
        </div>
    )
}

export default FreeQuoteForm;