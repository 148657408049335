import {Link, useNavigate} from "react-router";
import {useEffect} from "react";
import {useRecoilValue} from "recoil";
import AuthenticationStore from "../../../store/Authentication";
import LoadingComponent from "../../../components/Loading";

const LayoutPage = ({ children }) => {
    const navigate = useNavigate();
    const {isConnected} = useRecoilValue(AuthenticationStore);

    useEffect(() => {
        if (isConnected === true) {
            navigate('/');
        }
    }, [isConnected]);

    return (
        <LoadingComponent>
            <div className={"d-flex vh-100 w-100 justify-content-start align-items-stretch"}>
                <div className={'bg-primary h-auto w-50 d-none d-lg-block'} style={{height: 200}}>
                    <div className={'d-flex align-items-start flex-column h-100 p-5'}>
                        <div className={'mb-auto p-2'}>
                            <h1 className={'display-3 fw-bold'}><Link to={'/'} className={"text-decoration-none text-light"} >Transly</Link></h1>
                            <hr className={'text-light'}/>
                            <p className="lead text-light">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus eos impedit quos. Excepturi expedita facere, iste iure libero odio sunt tenetur voluptate.
                            </p>
                        </div>
                        <div className="p-2">
                            <Link className={'btn btn-link text-decoration-none text-light fw-bold'} to={'/user/sign_in'}>
                                Se connecter
                            </Link>
                        </div>
                        <div className="p-2">
                            <Link className={'btn btn-link text-decoration-none text-light fw-bold'}
                                  to={'/user/sign_up'}>S'inscriver</Link>
                        </div>
                    </div>
                </div>
                <div className={'w-75 p-5 bg-white'}>
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                        <div className={'w-100'}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </LoadingComponent>
    )
}

export {
    LayoutPage
}