import React, { useRef, useEffect } from 'react';
import {Player} from "@lordicon/react";

const LordIcon = ({ colors, src, size}) => {
    const playerRef = useRef(null);

    useEffect(() => {
        playerRef.current?.playFromBeginning();
    }, [])

    return (
        <Player
            colors={colors}
            ref={playerRef}
            icon={src}
            size={size}
            onComplete={() => playerRef.current?.playFromBeginning()}
        />
    );
};

export default LordIcon;
