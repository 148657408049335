import {LayoutPage} from "./Components";
import InitResetPasswordForm from "../../../components/forms/InitResetPasswordForm";

const ResetPassword = () => {

    const submitFormHandler = (values) => {
        console.log(values);
    }

    return (
        <LayoutPage>
            <div className={"d-flex justify-content-center align-items-center"}>
                <InitResetPasswordForm submit={submitFormHandler} />
            </div>
        </LayoutPage>
    )
}

export default ResetPassword;