import React, {useEffect, useState} from 'react';
import LordIcon from '../../components/LordIcon';
import {useRecoilState, useRecoilValue} from 'recoil';
import LoadingStore, {ProgressBarStore} from '../../store/Loading';
import {ProgressBar} from "react-bootstrap";

const SPINNER = require('../../assets/images/icons/spinner-three-dots.json');

export const ProgressBarLayoutComponent = ({ children }) => {
  const [running, setRunning] = useRecoilState(ProgressBarStore);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer;
    if (running && progress < 100) {
      timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress >= 100) {
            clearInterval(timer);
            setRunning(false);
            return 100;
          }
          return oldProgress + 10;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [running]);

  if (!running) return null;

  return <ProgressBar variant={"success"} now={progress} max={100} className={"rounded-0"}/>
}

export const LoadingLayoutComponent = () => (
  <section
    className={
      'd-flex justify-content-center align-content-center align-items-center py-5'
    }
  >
    <div className={'py-5'}>
      <div className={'text-center'}>
        <LordIcon
          colors={'#010101'}
          size={182}
          src={SPINNER}
        />
        <p>Loading ...</p>
      </div>
    </div>
  </section>
);

const LoadingComponent = ({children}) => {
  const loading = useRecoilValue(LoadingStore);

  return (
    <>
      {!loading ? children : (
        <section className={'z-3 position-absolute vh-100 w-100 bg-light bg-opacity-75 py-5'}>
          <div className={'py-5'}>
            <div className={'text-center'}>
              <LordIcon
                colors={'#010101'}
                size={182}
                src={SPINNER}
              />
              <p className={"text-dark"}>Loading ...</p>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LoadingComponent;
