import { Route, Routes } from "react-router";
import { PublicLayout } from "../components/layouts";
import {
    FreeQuotePage,
    HomePublicPage,
    NotFoundPage,
    ResetPasswordPublicPage,
    SignInPublicPage,
    SignUpPublicPage,
    ProfileProtectedPage,
    OrderProtectedPage,
    SecurityProtectedPage
} from "../Pages/Public";
import {
    ProtectedLayoutComponent
} from "../Pages/Public/Protected/components";


const RoutesApplication = () => (
    <Routes>
        <Route path="/" element={<PublicLayout />}>
            <Route index element={<HomePublicPage />} />
            <Route path={'free-quote'} element={<FreeQuotePage />} />
            <Route path={'user/sign_in'} element={<SignInPublicPage />} />
            <Route path={'user/sign_up'} element={<SignUpPublicPage />} />
            <Route path={'user/reset_password'} element={<ResetPasswordPublicPage />} />
            <Route path={'user'} element={<ProtectedLayoutComponent />}>
                <Route path="translation_order" element={<OrderProtectedPage />} />
                <Route path="profile" element={<ProfileProtectedPage />} />
                <Route path="security" element={<SecurityProtectedPage />} />
            </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
)

export default RoutesApplication;