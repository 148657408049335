import {
  QueryClient
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNotifications } from 'react-notifications-component';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
// import Authorisation from './authentication';
import RoutesApplication from './routes';

import './assets/styles/app.css';
import 'react-notifications-component/dist/theme.css';
import {useRecoilState, useRecoilValue} from "recoil";
import AuthenticationStore, {AuthenticationSelector} from "./store/Authentication";
import useIsMounted from "./hooks/useIsMounted";
import {useEffect} from "react";
import {AnimatePresence} from "framer-motion";
import {OrderTranslateModalComponent} from "./components/modals";
import {ProgressBarLayoutComponent} from "./components/Loading";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
});

const persister = createSyncStoragePersister({
    storage: window.localStorage,
});

function App() {
    const [authenticationStore, setAuthenticationStore] = useRecoilState(AuthenticationStore);
    const authentication = useRecoilValue(AuthenticationSelector);
    const isMounted = useIsMounted();

    useEffect(() => {
        if (isMounted() && authenticationStore?.isConnected) {
            console.log(authenticationStore)
        }
    }, [authenticationStore, isMounted]);

    useEffect(() => {
        if (isMounted()) {
            if (authentication) {
                setAuthenticationStore({
                    isConnected: true,
                    storageUser: authentication,
                });
            }
        }
    }, []);

    return (
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            <ReactNotifications />
            {/** <Authorisation ChildComponent={<RoutesApplication />} /> **/}
            <AnimatePresence mode="wait">
                <ProgressBarLayoutComponent />
                <RoutesApplication />
                <OrderTranslateModalComponent />
            </AnimatePresence>
            <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
    );
}

export default App;
