import {Formik} from "formik";
import {InputEmailComponent, InputPasswordComponent} from "../inputs";
import {Link} from "react-router";

const SignUpForm = ({ submit }) => {
    return (
        <div className={"w-50"}>
            <p className={"display-4 lead"}>S'inscrire</p>
            <p className={'lead'}>S'incrire pour profiter de l'application</p>
            <Formik
                initialValues={{email: '', password: '', confirm_password: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Cette information est requis';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'L\'adresse email est invalide';
                    }

                    if (values.password.length < 6) {
                        errors.password = 'Password must be at least 6 characters long';
                    }

                    if (values.confirm_password !== values.password) {
                        errors.confirm_password = 'Mot de passe de confirmation non correcte'
                    }

                    return errors;
                }}
                onSubmit={(values) => {
                    submit(values);
                }}
            >
                {({errors, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        {errors.email && <b className="text-danger">{errors.email}</b>}
                        <div className="form-floating mb-3">
                            <InputEmailComponent name="email"
                                                 id="registration_form_email"
                                                 className="form-control p-3"
                                                 placeholder="Email"
                                                 autoComplete="email"
                                                 required/>
                            <label htmlFor="registration_form_email">
                                Email
                            </label>
                        </div>
                        {errors.password && <b className="text-danger">{errors.password}</b>}
                        <div className="form-floating mb-3">
                            <InputPasswordComponent name="password" className="form-control p-3"
                                                    id="registration_form_plainPassword_first"
                                                    placeholder="Mot de passe"
                                                    required autoComplete="new-password"/>
                            <label htmlFor="registration_form_plainPassword_first">
                                Mot de passe
                            </label>
                        </div>
                        {errors.confirm_password && <b className="text-danger">{errors.confirm_password}</b>}
                        <div className="form-floating mb-3">
                            <InputPasswordComponent name="confirm_password"
                                                    id="registration_form_plainPassword_second"
                                                    className="form-control p-3"
                                                    placeholder="Confirmer le mot de passe"
                                                    required autoComplete="new-password"/>
                            <label htmlFor="registration_form_plainPassword_second">
                                Mot de passe
                            </label>
                        </div>
                        <div className="form-check pt-3 pb-3">
                            <input className="form-check-input"
                                   type="checkbox"
                                   name="registration_form[agreeTerms]"
                                   id="registration_form_agreeTerms"
                                   required/>
                            <label className="form-check-label" htmlFor="registration_form_agreeTerms">
                                J'accepte les conditions d'utilisation
                            </label>
                        </div>
                        <button className="btn btn-primary p-2 mb-3 w-100" type="submit">
                            S'inscrire
                        </button>
                        <Link to={'/user/sign_in'} className={"btn btn-link text-decoration-none text-secondary p-2 w-100"}>
                            Vous avez un compte ? Connectez-vous.
                        </Link>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default SignUpForm;