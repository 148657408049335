import {LayoutPage} from "./Components";
import {SignUpComponentForm} from "../../../components/forms";
import ApiService from "../../../services/Requests/ApiService";
import {errorFlashNotification, successFlashNotification} from "../../../components/others/FlashNotification";
import {useState} from "react";
import EmailVerificationForm from "../../../components/forms/EmailVerificationForm";

const SignUp = (props) => {
    const [step, setStep] = useState(0);

    const submitFormHandler = (values) => {
        ApiService
            .post("", JSON.stringify(values))
            .then(response => successFlashNotification())
            .catch(errors => errorFlashNotification(errors))
    }

    const submitVerificationFromHandler = (values) => {
        console.log(values)
    }

    return (
        <LayoutPage>
            <div className={"d-flex justify-content-center align-items-center"}>
                {
                    step === 0 ? <SignUpComponentForm submit={submitFormHandler} /> : <EmailVerificationForm submit={submitVerificationFromHandler} />
                }
            </div>
        </LayoutPage>
    )
}

export default SignUp;