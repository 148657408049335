import { Outlet } from "react-router";

const PublicLayout = () => {

    return (
        <main className={"d-flex align-items-center align-self-stretch bg-light w-100 p-0 m-0"}>
            <Outlet />
        </main>
    )
}

export default PublicLayout;