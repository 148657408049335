import {LayoutPage} from "./Components";
import {FreeQuoteComponentForm} from "../../../components/forms";

const FreeQuote = () => {

    const handlerSubmit = (values) => {
        console.log("handlerSubmit", values);
    }

    return (
        <LayoutPage>
            <p className={"display-4 lead"}>Demande de dévis</p>
            <p className={'lead'}>Faire la démande de dévis pour la traduction de vos documents</p>
            <FreeQuoteComponentForm submit={handlerSubmit}/>
        </LayoutPage>
    );
}

export default FreeQuote;