import {Link} from "react-router";

const HeaderPageComponent = ({authentication}) => (
    <div className={"bg-light border-bottom"}>
        <header
            className={"container d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 bg-light"}>
            <div className={"col-md-3 mb-2 mb-md-0"}>
                <Link to={"/"} className={"d-inline-flex link-body-emphasis fs-1 fw-bold text-decoration-none"}>
                    Transly
                </Link>
            </div>
            {/**
             <ul className={"nav col-12 col-md-auto mb-2 justify-content-center mb-md-0"}>
             <li><Link to={"/"} className="nav-link px-2">Pricing</Link></li>
             <li><Link to={"/"} className="nav-link px-2">FAQs</Link></li>
             <li><Link to={"/"} className="nav-link px-2">About</Link></li>
             </ul>
             **/}


            <div className="col-md-3 text-end">
                {
                    !authentication.isConnected ? (
                        <>
                            <Link to={"/user/sign_in"} type="button" className={"btn btn-outline-primary me-2"}>Se
                                connecter</Link>
                            <Link to={"/user/sign_up"} className={"btn btn-primary"}>S'inscrire</Link>
                        </>
                    ) : (
                        <>
                            <Link to={"/user/profile"} className={"btn btn-primary"}>Mon profile</Link>
                        </>
                    )
                }

            </div>
        </header>
    </div>
)

const FooterPageComponent = () => (
    <footer className={"d-flex flex-wrap justify-content-between align-items-center py-5 bg-light border-top"}>
        <div className={"col-md-4 d-flex align-items-center"}>
            <a href="/" className={"mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"}>
                <svg className="bi" width="30" height="24">
                    <use xlinkHref="#bootstrap"></use>
                </svg>
            </a>
            <span className={"mb-3 mb-md-0 text-body-secondary"}>© 2024 Company, Inc</span>
        </div>

        <ul className={"nav col-md-4 justify-content-end list-unstyled d-flex"}>
            <li className={"ms-3"}>
                <a className={"text-body-secondary"} href="#">
                    <svg className="bi" width="24" height="24">
                        <use xlinkHref="#twitter"></use>
                    </svg>
                </a>
            </li>
            <li className={"ms-3"}>
                <a className={"text-body-secondary"} href="#">
                    <svg className={"bi"} width="24" height="24">
                        <use xlinkHref="#instagram"></use>
                    </svg>
                </a>
            </li>
            <li className={"ms-3"}>
                <a className={"text-body-secondary"} href="#">
                    <svg className={"bi"} width="24" height="24">
                        <use xlinkHref="#facebook"></use>
                    </svg>
                </a>
            </li>
        </ul>
    </footer>
)

export {
    HeaderPageComponent,
    FooterPageComponent
}